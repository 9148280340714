

























import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";
import UserModule from "@/store/userModule";
import companyEnquiryEditRoutes from '../../../router/companiesEnquiryEdit';

const userModule = getModule(UserModule);

@Component
export default class VesselEnquiryEditIndex extends Vue {
  private get routes() {
    return companyEnquiryEditRoutes;
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }
}
